import React from 'react'
import './About.css'

const About = () => {
    return (
        <div className="aboutPage">
            <div className="aboutContainer">
                <h1>À Propos de Nous</h1>
                <p>
                    Bienvenue sur notre page À Propos! Je suis [Votre Nom], le fondateur de [Nom de
                    l'Entreprise], votre partenaire de confiance pour toutes vos solutions
                    informatiques.
                </p>
                <p>
                    Avec une passion pour l'informatique et une expérience solide accumulée au fil
                    des années, j'ai décidé de lancer [Nom de l'Entreprise] pour offrir des services
                    de haute qualité dans le domaine de l'informatique aux particuliers et aux
                    entreprises.
                </p>
                <p>
                    Notre mission est de fournir des solutions informatiques efficaces et
                    personnalisées pour répondre aux besoins spécifiques de chaque client. Nous nous
                    engageons à utiliser les dernières technologies et les meilleures pratiques pour
                    assurer la satisfaction et le succès de nos clients.
                </p>
                <h2>Pourquoi Nous Choisir?</h2>
                <ul>
                    <li>
                        <strong>Expertise : </strong>Profitez de notre expertise technique
                        approfondie dans divers domaines informatiques.
                    </li>
                    <li>
                        <strong>Personnalisation : </strong>Chaque solution est conçue pour répondre
                        spécifiquement à vos besoins.
                    </li>
                    <li>
                        <strong>Support : </strong>Nous offrons un support fiable et réactif pour
                        tous nos services.
                    </li>
                    <li>
                        <strong>Qualité : </strong>La qualité de service est au cœur de notre
                        démarche, garantissant votre satisfaction.
                    </li>
                </ul>
                <p>
                    Nous sommes impatients de travailler avec vous et de vous aider à réaliser vos
                    objectifs informatiques. Contactez-nous dès aujourd'hui pour découvrir comment
                    nous pouvons vous assister!
                </p>
            </div>
        </div>
    )
}

export default About
